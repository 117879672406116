<template>
  <div class="catesub favorite">
    <van-empty
      v-if="!goods || goods.length < 1"
      description="你还没有收藏任何商品"
    />
    <div v-else class="goods-list">
      <GoodsItem
        :item="item"
        page="fav"
        v-for="item in goods"
        :key="item.iid"
      />
    </div>
  </div>
</template>


<script>
import GoodsItem from "@/components/GoodsItem.vue";
export default {
  name: "Favorite",
  data() {
    return {
      itemsCount: 0,
      move: false,
      goods: null,
      page: 0,
      tabid: null,
      bottom: "0",
      right: "0",
    };
  },
  components: {
    GoodsItem,
  },
  created() {
    this.getGoods();
  },
  methods: {
    favToggle(id) {
      this.$ajax
        .post(`/user/favor/remove/`, {  iid: id }, "loading")
        .then(() => {
          for (let i = 0; i < this.goods.length; i++) {
            const item = this.goods[i];
            if (id === item.iid) {
              this.goods.splice(i, 1);
              break;
            }
          }
          this.$toast.fail("已取消收藏");
        });
    },
    getGoods() {
      ;
      this.$ajax
        .post("/user/favor/index/", {

        })
        .then((res) => {
          this.goods = res.data;
        });
    },
  },
};
</script>